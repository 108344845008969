import './Homepage.scss'
import MainBlock from "../../components/homepage/mainBlock/MainBlock";
import HomePartners from "../../components/homepage/partners/HomePartners";
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";
import HomeBlock from "../../components/homepage/homeBlock/HomeBlock";
import HomeServices from "../../components/homepage/homeServices/HomeServices";
import HomeNews from "../../components/homepage/homeNews/HomeNews";
import HomeContact from "../../components/homepage/homeContact/HomeContact";
import HomePackage from "../../components/homepage/homePackage/HomePackage";
import {useEffect} from "react";

function Homepage(props){
    const {partners, staticTexts,home,lastNews,contacts,requestLoading,services} = props;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return <div className={'homepage-wrapper'}>
        <MainBlock staticTexts={staticTexts} home={home} requestLoading={requestLoading}/>
        <HomePartners partners={partners} staticTexts={staticTexts}/>
        <HomeBlock staticTexts={staticTexts} home={home}/>
        <HomeServices staticTexts={staticTexts} services={services}/>
        <HomePackage staticTexts={staticTexts}/>
        <HomeNews news={lastNews} staticTexts={staticTexts}/>
        <HomeContact contacts={contacts} staticTexts={staticTexts}/>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'partners',
        'requestLoading',
        'staticTexts',
        'contacts',
        'projects',
        'sponsors',
        'home',
        'lastNews',
        'services',
        'blogs'
    ])
};

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);