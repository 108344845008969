// Import packages
import React from "react";
import {Route, Routes,Navigate} from "react-router-dom";
import Layout from "../containers/Layout";

// Import utils

// Import pages
import Homepage from "../containers/homepage/Homepage";
import Services from "../containers/service/Services";
import About from "../containers/about/About";
import News from "../containers/news/News";
import Contact from "../containers/contact/Contact";
import Product from "../containers/product/Product";



export default function RoutesBlocks() {
    return <Layout>
        <Routes>
            <Route path="/" element={<Homepage/>}/>
            <Route path="/services" element={<Services/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/news" element={<News/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/products" element={<Product/>}/>
            {/*<Route path="/fitness/:id" element={<FitnessSection/>}/>*/}
            {/*<Route path="/spa" element={<Spa/>}/>*/}
            {/*<Route path="/cafe" element={<Cafe/>}/>*/}
            {/*<Route path="/event/detail/:id" element={<EventDetail/>}/>*/}
            {/*<Route path="/article/detail/:id" element={<ArticleDetail/>}/>*/}
            {/*<Route path="/pool" element={<Pool/>}/>*/}
            {/*<Route path="/404" element={<NotFound/>}/>*/}
            {/*<Route path="*" element={ <Navigate to="/404" replace />} />*/}
        </Routes>
    </Layout>
}


