import './HomeContact.scss'
import {CallIcon, ContactImg} from "../../../assets/images";

function HomeContact(props){
    const {contacts,staticTexts} = props

    return <div className={'homeContact-wrapper'}>
        <div className={'homeContact-img'}>
            <img src={ContactImg} alt=""/>
        </div>
        <div className={'homeContact-bottom'}>
            <div className={'homeContact-info'}>
                <div className={'homeContact-title'}>
                    {staticTexts?.homepage_contact_title}
                </div>
                <div className={'homeContact-description'}>
                    {staticTexts?.homepage_contact_description}
                </div>
            </div>
            <a href={`tel:${'060 55 55 55'}`} className={'homeContact-number'}>
                <CallIcon/>060 55 55 55
            </a>
        </div>
    </div>
}export default HomeContact