import './HomeBlock.scss'
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";


function HomeBlock(props) {
    const {staticTexts,home} = props
    console.log(home,'homehome')

    return <div className={'homeBlock-wrapper'}>
        <div className={'home-left-block'}>
            <div className={'homeBlock-title'}>
                {home?.title}
            </div>
            <div className={'homeBlock-description'}>
                {home?.description}
            </div>
            <Link to={'/about'} className={'homeBlock-more'}>
                {staticTexts?.homepage_more_info_btn}
            </Link>
        </div>
        <div className={'home-right-block'}>
            <div className={'home-imag'}>
                <img src={generateImageMediaUrl(home?.mediaMain?.path)} alt=""/>
            </div>
        </div>
    </div>
}export default HomeBlock